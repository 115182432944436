import * as React from "react"
import { displayDiscountedPrice, displayPrice } from "./utilityMethods";
import {originalPrice} from "./Price.module.css";

const DiscountedPrice = ({photo}) => {
    return (
        <>
            <span className={originalPrice}>{displayPrice(photo)}</span>
            <span>{displayDiscountedPrice(photo)}</span>
        </>
    );
}

const NormalPrice = ({photo}) => {
    return <span>{displayPrice(photo)}</span>;
}

const Price = ({photo}) => {
    return photo.discountedPrice ? <DiscountedPrice photo={photo}/> : <NormalPrice photo={photo}/>;
}

export default Price;