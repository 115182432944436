import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import Gallery from "../../components/Gallery"
import Lightbox from "../../components/Lightbox"
import { discountedPrice } from "../../components/utilityMethods"
const {useState} = React;

const GalleryPage = ({data}) => {
    const [lightBoxDisplay, setLightBoxDisplay] = useState(false);
    const [lightBoxIsFullScreen, setLightBoxIsFullScreen] = useState(false);
    const [lightBoxPhotoIndex, setLightBoxPhotoIndex] = useState(0);

    const artworks = data.contentfulGallery.artworks ? data.contentfulGallery.artworks : [];

    artworks.forEach(artwork => {
        if(!artwork.mainImage) {
            console.log(`Image not found for ${artwork.title}`);
        }
    });

    artworks
        .map(artwork => artwork.title)
        .filter((v, i, a) => a.indexOf(v) !== i)
        .forEach(title => {
            console.log(`${title} appears multiple times in gallery ${data.contentfulGallery.title}.`);
        });

    const photosInGallery = artworks
        .filter(artwork => artwork.mainImage)
        .map((artwork, i) => {
            return {
                title: artwork.title,
                key: artwork.title + " " + i,
                dimensions: {
                    width: artwork.width,
                    height: artwork.height
                },
                width: artwork.mainImage.file.details.image.width,
                height: artwork.mainImage.file.details.image.height,
                gatsbyImageData: artwork.mainImage.gatsbyImageData,
                otherImages: artwork.otherImages ? artwork.otherImages.map(
                    (otherImage, i) => {
                        return {
                            gatsbyImageData: otherImage.gatsbyImageData,
                            name: otherImage.id,
                            index: i + 1
                        };
                    }
                ) : [],
                src: "",
                medium: artwork.medium,
                surface: artwork.surface,
                framing: artwork.framing,
                availability: artwork.availability ? artwork.availability.childMarkdownRemark.html : null,
                sold: artwork.sold ?? false,
                isForSale: artwork.isForSale ?? true,
                price: artwork.price,
                discountedPrice: discountedPrice(artwork.price, artwork.discountedPrice)
            };
        }
    );

    const showLightBox = (photo) => {
        const photoIndex = photosInGallery.map(x => x.key).indexOf(photo.key);
        setLightBoxPhotoIndex(photoIndex);
        setLightBoxDisplay(true);
        setLightBoxIsFullScreen(false);
    }

    const hideLightBox = () => {
        setLightBoxDisplay(false);
        setLightBoxIsFullScreen(false);
    }

    const enterFullScreenMode = () => {
        setLightBoxIsFullScreen(true);
        setLightBoxDisplay(true);
    }

    const exitFullScreenMode = () => {
        setLightBoxIsFullScreen(false);
        setLightBoxDisplay(true);
    }

    const prevIndex = (currentIndex) => {
        return currentIndex === 0 ? photosInGallery.length - 1 : currentIndex - 1;
    }

    const nextIndex = (currentIndex) => {
        return currentIndex === photosInGallery.length - 1 ? 0 : currentIndex + 1;
    }

    const showPreviousPhotoInLightbox = () => {
        setLightBoxPhotoIndex(prevIndex(lightBoxPhotoIndex));
    }

    const showNextPhotoInLightbox = () => {
        setLightBoxPhotoIndex(nextIndex(lightBoxPhotoIndex));
    }

    const lightboxMethods = {
        enterFullScreen: enterFullScreenMode,
        exitFullScreen: exitFullScreenMode,
        leftButton: showPreviousPhotoInLightbox,
        rightButton: showNextPhotoInLightbox,
        close: hideLightBox
    };

    const lightboxPhotos = {
        prevPhoto: photosInGallery[prevIndex(lightBoxPhotoIndex)],
        currentPhoto: photosInGallery[lightBoxPhotoIndex],
        nextPhoto: photosInGallery[nextIndex(lightBoxPhotoIndex)]
    };

    const animationSettings = {
        animationDisabled: false,
        animationOnKeyInput: true,
        lightboxAnimationDuration: 300,
        fullScreenAnimationDuration: 200
    };

    const keyboardSettings = {
        keyRepeatKeyupBonus: 40,
        keyRepeatLimit: 180
    };

    return (
        <div>
            <Layout key={data.contentfulGallery.title} pageTitle={data.contentfulGallery.title}>
                <Gallery 
                    galleryTitle={data.contentfulGallery.title} 
                    description={data.contentfulGallery.description}
                    onPhotoClick={showLightBox}
                    photosInGallery={photosInGallery}
                />
            </Layout>
            {lightBoxDisplay ? 
                <Lightbox 
                    photos={lightboxPhotos}
                    galleryTitle={data.contentfulGallery.title}
                    lightboxMethods={lightboxMethods}
                    fullScreen={lightBoxIsFullScreen}
                    animationSettings={animationSettings}
                    keyboardSettings={keyboardSettings}
                /> : ''
            }
        </div>
    );
}

export const query = graphql`
    query ($id: String) {
        contentfulGallery(id: {eq: $id}) {
            title
            artworks {
                title
                sold
                availability {
                    childMarkdownRemark {
                        html
                    }
                }
                price
                discountedPrice
                medium
                surface
                framing
                width
                height
                mainImage {
                    gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
                    file {
                        details {
                            image {
                                height
                                width
                            }
                        }
                    }
                }
            }
            description {
                raw
            }
        }
    }
`;

export default GalleryPage;
