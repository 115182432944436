import * as React from "react"
import Gallery from "react-photo-gallery";
import GalleryItem from "./GalleryItem";

import {
    galleryItemContainer,
    galleryItemGatsbyImage
} from "./AutomaticRowsGallery.module.css"

const AutomaticRowsGallery = ({
    photos,
    galleryTitle,
    onPhotoClick
}) => {
    const imageRenderer = ({ key, photo }) => {
        
        const containerStyle = {
            width: `${photo.width + 40}px`,
            height: `${photo.height + 40}px`
        }

        return (
            <GalleryItem
                className={"gallery-image"}
                key={key}
                photo={photo}
                galleryTitle={galleryTitle}
                onPhotoClick={onPhotoClick}
                containerStyle={containerStyle}
                containerClassName={galleryItemContainer}
                gatsbyImageClassName={galleryItemGatsbyImage}
            />
        );
    }

    return (
        <div className="gallery">
            <div className="container">
                <Gallery 
                    photos={photos} 
                    renderImage={imageRenderer}
                    margin={20}
                />
            </div>
        </div>
    );
}

export default AutomaticRowsGallery;