import * as React from "react"
import { displayTextWhenNotForSale, displayTextWhenSold } from "./utilityMethods";
import parse from "html-react-parser";
import Price from "./Price";
import {
    container,
    hiddenContainer,
    details
} from "./LightboxContent.module.css"

const SurfaceAndDimensions = ({photo}) => {
    const surface = photo.surface ? photo.surface : '';

    const dimensions = photo.dimensions.width && photo.dimensions.height ? 
        `${photo.dimensions.height} x ${photo.dimensions.width} cm` :
        '';

    return <p>{`${surface} ${dimensions}`}</p>
}

const PriceDetails = ({photo, galleryTitle}) => {
    if(photo.sold) return <h3>{displayTextWhenSold(galleryTitle)}</h3>;
    if(!photo.isForSale) return <h3>{displayTextWhenNotForSale()}</h3>;
        
    return (
        <div>
            {photo.price ? <h3><Price photo={photo}/></h3> : ''}
            {photo.availability ? parse(photo.availability) : ''}
        </div>
    );
}

const LightboxContent = ({photo, galleryTitle, visible}) => {
    const clickableContainerClassName = `
        ${container} 
        ${visible ? '' : hiddenContainer} 
    `;

    return (                
        <div className={clickableContainerClassName}>
            <h3>{photo.title}</h3>
            <div className={details}>
                {photo.medium ? <p>{photo.medium}</p> : ''}
                <SurfaceAndDimensions photo={photo}/>
                {photo.framing ? <p>{photo.framing}</p> : ''}
                <PriceDetails photo={photo} galleryTitle={galleryTitle}/>
            </div>
        </div>
    );
}

export default LightboxContent;