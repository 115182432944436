import * as React from "react"
import AutomaticRowsGallery from "./AutomaticRowsGallery"
import ScrollingGallery from "./ScrollingGallery"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
    ScrollingGalleryIcon,
    AutomaticRowsGalleryIcon
} from "./Icons.jsx"
import {
    container,
    gallerySelectorButtonsOuterContainer,
    gallerySelectorButtonsContainer,
    gallerySelectorButtonsIndicator,
    automaticRowsSelected,
    scrollingContentSelected
} from "./Gallery.module.css"
const {useState} = React;

class GalleryModeEnum {
    static AutomaticRows = Symbol("automatic rows");
    static Scrolling = Symbol('scrolling'); 

    constructor(name) {
        this.name = name;
    }
}

const GalleryImages = ({photosInGallery, galleryTitle, onPhotoClick, galleryMode}) => {
    switch(galleryMode) {
        case GalleryModeEnum.Scrolling:
            return (
                <ScrollingGallery photos={photosInGallery} galleryTitle={galleryTitle} onPhotoClick={onPhotoClick}/>
            );
        case GalleryModeEnum.AutomaticRows: 
        default:
            return (
                <AutomaticRowsGallery photos={photosInGallery} galleryTitle={galleryTitle} onPhotoClick={onPhotoClick}/>
            );
    }
}

const GalleryDescription = ({description}) => {
    return description ? documentToReactComponents(JSON.parse(description.raw)) : '';
}

const Gallery = ({galleryTitle, description, onPhotoClick, photosInGallery}) => {
    const [galleryMode, setGalleryMode] = useState(GalleryModeEnum.AutomaticRows);

    const setAutomaticRowsGallery = (e) => {
        setGalleryMode(GalleryModeEnum.AutomaticRows);
    }

    const setScrollingRowsGallery = (e) => {
        setGalleryMode(GalleryModeEnum.Scrolling);
    }
    return (
        <div className={container}>
            <div className={gallerySelectorButtonsOuterContainer}>
                <div className={gallerySelectorButtonsContainer}>
                    <button onClick={setAutomaticRowsGallery}>
                        <svg viewBox={"0 0 50 50"} preserveAspectRatio="xMidYMid slice"><AutomaticRowsGalleryIcon/></svg>
                    </button>
                    <button onClick={setScrollingRowsGallery}>
                        <svg viewBox={"0 0 55 55"} preserveAspectRatio="xMidYMid meet"><ScrollingGalleryIcon/></svg>
                    </button>
                </div>
                <div className={`${gallerySelectorButtonsIndicator} ${galleryMode === GalleryModeEnum.AutomaticRows ? automaticRowsSelected : scrollingContentSelected}`}></div>
            </div>
            <h1>{galleryTitle}</h1>
            <GalleryDescription description={description}/>
            <GalleryImages galleryMode={galleryMode} onPhotoClick={onPhotoClick} photosInGallery={photosInGallery} galleryTitle={galleryTitle}/>  
        </div>
    );
}

export default Gallery;