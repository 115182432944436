import * as React from "react"
import GalleryItem from "./GalleryItem"
import {
    container,
    GalleryItemGatsbyImage,
    GalleryItemContainingDiv
} from "./ScrollingGallery.module.css"

const ScrollingGallery = ({
    photos,
    galleryTitle,
    onPhotoClick
}) => {
    return (
        <div className="gallery">
            <div className={container}>
                {photos.map((photo) => {
                    return (
                        <GalleryItem
                            className={"gallery-image"}
                            key={photo.title}
                            photo={photo}
                            galleryTitle={galleryTitle}
                            onPhotoClick={onPhotoClick}
                            containerClassName={GalleryItemContainingDiv}
                            gatsbyImageClassName={GalleryItemGatsbyImage}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default ScrollingGallery;