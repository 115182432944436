// extracted by mini-css-extract-plugin
export var centreContainer = "Lightbox-module--centreContainer--f25e2";
export var changeImageButton = "Lightbox-module--changeImageButton--5e1c8";
export var clickableContainer = "Lightbox-module--clickableContainer--d81e9";
export var flexSpacer = "Lightbox-module--flexSpacer--0b56f";
export var flexSpacerHidden = "Lightbox-module--flexSpacerHidden--27094";
export var lightbox = "Lightbox-module--lightbox--249d1";
export var lightboxClosing = "Lightbox-module--lightboxClosing--118b9";
export var lightboxImage = "Lightbox-module--lightboxImage--8d86b";
export var nextImageButton = "Lightbox-module--nextImageButton--4574d Lightbox-module--changeImageButton--5e1c8";
export var opaqueLightboxBackground = "Lightbox-module--opaqueLightboxBackground--2a6cf";
export var overlay = "Lightbox-module--overlay--fe5e7";
export var overlayHeader = "Lightbox-module--overlayHeader--59ee3";
export var overlayMain = "Lightbox-module--overlayMain--3cd11";
export var prevImageButton = "Lightbox-module--prevImageButton--743aa Lightbox-module--changeImageButton--5e1c8";
export var transparentLightboxBackground = "Lightbox-module--transparentLightboxBackground--20bba";
export var zoomInCursor = "Lightbox-module--zoomInCursor--1e096";
export var zoomOutCursor = "Lightbox-module--zoomOutCursor--51baa";