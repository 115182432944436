import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { displayPrice, displayTextWhenNotForSale, displayTextWhenSold } from "./utilityMethods"
import { 
  galleryImage,
  galleryImageButton, 
  galleryItemInformationStyle,
  galleryItemTitle,
  galleryItemPrice
} from "./GalleryItem.module.css"
import Price from "./Price"

const GalleryItemTitle = ({photo}) => {
  return <h3 className={galleryItemTitle}>{photo.title}</h3>
}

const GalleryItemPrice = ({photo, galleryTitle}) => {
  const price = photo.sold ? displayTextWhenSold(galleryTitle) : 
    photo.isForSale ? <Price photo={photo}/> :
      displayTextWhenNotForSale();

  return <h3 className={galleryItemPrice}>{price}</h3>;
}

const GalleryItemInformation = ({photo, galleryTitle}) => {
  return (
    <div className={galleryItemInformationStyle}>
      <GalleryItemTitle photo={photo}/>
      <GalleryItemPrice photo={photo} galleryTitle={galleryTitle}/>
    </div>
  );
}

const GalleryItem = ({
    photo,
    galleryTitle,
    onPhotoClick,
    containerStyle,
    containerClassName,
    gatsbyImageClassName
  }) => {
    const handleOnClick = (e) => {
        onPhotoClick(photo);
    };

    return (
      <div
        className={containerClassName}
        style={containerStyle}
      >
        <button 
          onClick={handleOnClick}
          className={galleryImageButton}
        >
          <div>
            <GatsbyImage
              className={`${galleryImage} ${gatsbyImageClassName}`}
              alt={photo.title}
              image={photo.gatsbyImageData}
              />
            <GalleryItemInformation photo={photo} galleryTitle={galleryTitle}/>
            
          </div>
        </button>
      </div>
    );
}

export default GalleryItem;