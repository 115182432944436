import * as React from "react"
import { useState, useRef, useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image"
import {
    MinimiseFullScreenIcon,
    FullScreenIcon,
    CloseIcon,
    RightAngledBracketIcon, 
    LeftAngledBracketIcon
} from "./Icons";
import LightboxContent from "./LightboxContent";
import {
    KEYS
} from "./constants.js"
import {
    lightbox,
    opaqueLightboxBackground,
    transparentLightboxBackground,
    lightboxClosing,
    lightboxImage,
    clickableContainer,
    zoomInCursor,
    zoomOutCursor,
    overlay,
    overlayHeader,
    overlayMain,
    centreContainer,
    flexSpacer,
    flexSpacerHidden,
    nextImageButton,
    prevImageButton
} from "./Lightbox.module.css"

const toggleFullScreen = (fullScreen, lightboxMethods) => {
    return fullScreen ? lightboxMethods.exitFullScreen : lightboxMethods.enterFullScreen;
}

const ToggleFullscreenButton = ({fullScreen, lightboxMethods}) => {
    return (
        <button onClick={toggleFullScreen(fullScreen, lightboxMethods)}>
            <svg viewBox={"0 0 24 24"}>
                {fullScreen ? <MinimiseFullScreenIcon/> : <FullScreenIcon/>}
            </svg>
        </button>
    );
}

const CloseLightboxButton = ({closeMethod}) => {
    return (
        <button onClick={closeMethod}>
            <svg viewBox={"0 0 24 24"}>
                <CloseIcon/>
            </svg>
        </button>
    );
}

const PreviousImageButton = ({previousImageMethod}) => {
    return (
        <button className={prevImageButton} onClick={previousImageMethod}>
            <svg viewBox={"0 0 24 24"}>
                <LeftAngledBracketIcon/>
            </svg>
        </button>
    );
}

const NextImageButton = ({nextImageMethod}) => {
    return (
        <button className={nextImageButton} onClick={nextImageMethod}>
            <svg viewBox={"0 0 24 24"}>
                <RightAngledBracketIcon/>
            </svg>
        </button>
    );
}

const LightboxImage = ({image, title}) => {
    return (
        <GatsbyImage
            className={lightboxImage}
            image={image} 
            alt={title}
            objectFit={"contain"}
        />
    );
}

const FlexSpacer = ({fullScreen}) => {
    const className = `${flexSpacer} ${fullScreen ? flexSpacerHidden : ''} close-on-click`;
    
    return (
        <div className={className}></div>
    )
}

const LightboxContainer = ({photo, galleryTitle, lightboxMethods, fullScreen}) => {
    const centreContainerClassName = `${centreContainer} close-on-click`;
    
    const clickableContainerClassName = `
        ${clickableContainer} 
        ${fullScreen ? zoomOutCursor : zoomInCursor}
    `;
    const clickableContainerOnClick = fullScreen ? lightboxMethods.exitFullScreen : lightboxMethods.enterFullScreen;

    return (
        <div className={centreContainerClassName}>
            <FlexSpacer fullScreen={fullScreen}/>
            <button className={clickableContainerClassName} onClick={clickableContainerOnClick}>
                <LightboxImage
                    title={photo.title}
                    image={photo.gatsbyImageData}
                    objectFit="contain"
                />
            </button>
            <FlexSpacer fullScreen={fullScreen}/>
            <LightboxContent 
                photo={photo} 
                galleryTitle={galleryTitle}
                visible={!fullScreen}
            />
            <FlexSpacer fullScreen={fullScreen}/>
        </div>
    );
}

const Lightbox = ({
    photos,
    galleryTitle,
    lightboxMethods,
    fullScreen,
    animationSettings,
    keyboardSettings
}) => {
    const [isAnimating, setIsAnimating] = useState(false);
    const [shouldAnimate, setShouldAnimate] = useState(false);
    const [isClosing, setIsClosing] = useState(!animationSettings.animationDisabled);
    const [keyPressed, setKeyPressed] = useState(false);
    
    const [lastKeyDownTime, setLastKeyDownTime] = useState(0);

    const lightboxReference = useRef(null);
    useEffect(() => {
        lightboxReference.current.focus();
        if(!animationSettings.animationDisabled) {
            setIsClosing(false);
        }
    }, [animationSettings]);

    const lightboxClassNames = `
        ${lightbox} 
        ${fullScreen ? opaqueLightboxBackground : transparentLightboxBackground} 
        ${isClosing ? lightboxClosing : ''}
        close-on-click
    `;

    const requestClose = (event) => {
        const closeLightbox = () => lightboxMethods.close(event);

        if (animationSettings.animationDisabled || (event.type === 'keydown' && !animationSettings.animationOnKeyInput)) {
            // No animation
            closeLightbox();
            return;
        }

        // With animation
        // Start closing animation
        setIsClosing(true);

        // Perform the actual closing at the end of the animation
        setTimeout(closeLightbox, animationSettings.lightboxAnimationDuration);
    }

    const closeIfClickedOutside = (event) => {
        if(event.target.nodeName === "DIV" && event.target.className.search(/\bclose-on-click\b/) > -1) {
            // Clicked on something with "close-on-click" class
            requestClose(event);
        }
    }

    const resetZoom = () => {
        // TODO: Reset zoom state
    }

    const requestMove = (event, moveDirection) => {
        if (!animationSettings.animationDisabled && (!keyPressed || animationSettings.animationOnKeyInput)) {
            setShouldAnimate(true);
            setTimeout(() => setShouldAnimate(false), animationSettings.lightboxAnimationDuration);
        }

        setKeyPressed(false);
        resetZoom();

        if (moveDirection === "prev") {
            lightboxMethods.leftButton();
        } else {
            lightboxMethods.rightButton();
        }
    }

    const handleKeyInput = (event) => {
        event.stopPropagation();
    
        // Ignore key input during animations
        if (isAnimating) return;
    
        // Allow slightly faster navigation through the images when user presses keys repeatedly
        if (event.type === 'keyup') {
            setLastKeyDownTime(lastKeyDownTime - keyboardSettings.keyRepeatKeyupBonus);
            return;
        }
    
        const keyCode = event.which || event.keyCode;
    
        // Ignore key presses that happen too close to each other (when rapid fire key pressing or holding down the key)
        // But allow it if it's a lightbox closing action
        const currentTime = new Date();
        if (currentTime.getTime() - lastKeyDownTime < keyboardSettings.keyRepeatLimit && keyCode !== KEYS.ESC) return;
        
        setLastKeyDownTime(currentTime.getTime());
    
        switch (keyCode) {
          // ESC key closes the lightbox
            case KEYS.ESC:
                event.preventDefault();
                requestClose(event);
                break;
    
            // Left arrow key moves to previous image
            case KEYS.LEFT_ARROW:    
                event.preventDefault();
                setKeyPressed(true);
                requestMove(event, "prev");
                break;
    
            // Right arrow key moves to next image
            case KEYS.RIGHT_ARROW:
                event.preventDefault();
                setKeyPressed(true);
                requestMove(event, "next");
                break;
    
            case KEYS.F:
                event.preventDefault();
                toggleFullScreen(fullScreen, lightboxMethods)();
                break;

          default:
        }
    }

    return (
        <div 
            className={lightboxClassNames} 
            onClick={closeIfClickedOutside} 
            onKeyDown={handleKeyInput}
            onKeyUp={handleKeyInput}
            tabIndex={-1}
            ref={lightboxReference}
            style={
                {
                    transition: `opacity ${animationSettings.lightboxAnimationDuration}ms, background-color ${animationSettings.fullScreenAnimationDuration}ms`,
                    animationDuration: `${animationSettings.lightboxAnimationDuration}ms`,
                    animationDirection: isClosing ? 'normal' : 'reverse'
                }
            }
        >
            <div className={`${overlay} close-on-click`}>
                <div className={`${overlayMain} close-on-click`}>
                    <PreviousImageButton previousImageMethod={lightboxMethods.leftButton}/>
                    <NextImageButton nextImageMethod={lightboxMethods.rightButton}/>
                </div>
                <div className={overlayHeader}>
                    <ToggleFullscreenButton fullScreen={fullScreen} lightboxMethods={lightboxMethods}/>
                    <CloseLightboxButton closeMethod={lightboxMethods.close}/>
                </div>
            </div>
            <LightboxContainer photo={photos.currentPhoto} galleryTitle={galleryTitle} lightboxMethods={lightboxMethods} fullScreen={fullScreen}/>
        </div>
    );
}

export default Lightbox;